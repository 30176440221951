import { createApp } from 'vue'
import "bootstrap/dist/css/bootstrap.min.css"
import App from './App.vue'
import router from './router'
import store from './store'

import GAuth from 'vue3-google-oauth2'
const gAuthOptions = {
    clientId: '587643005900-hm3gbcvgarkrgfj52hqcv585h4h2e1ci.apps.googleusercontent.com',
    scope: 'email',
    prompt: 'consent',
    fetch_basic_profile: false
}

createApp(App).use(store).use(router)
    .use(GAuth, gAuthOptions)
    .mount('#app')
import "bootstrap/dist/js/bootstrap.js"
