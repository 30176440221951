<template>
  <div class="container">
    <header
      class="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mb-4 border-bottom"
    >
      <a
        href="/"
        class="d-flex align-items-center col-md-3 mb-2 mb-md-0 text-dark text-decoration-none"
      >
        <svg
          class="bi me-2"
          width="40"
          height="32"
          role="img"
          aria-label="Bootstrap"
        >
          <use xlink:href="#bootstrap"></use>
        </svg>
      </a>

      <ul class="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0">
        <li>
          <router-link
            :to="{ name: 'Home' }"
            class="nav-link px-2 link-dark"
            active-class="active"
          >
            Home</router-link
          >
        </li>
        <li>
          <router-link :to="{ name: 'About' }" class="nav-link px-2 link-dark"
            >About</router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'GoogleAuth' }"
            class="nav-link px-2 link-dark"
            >GoogleAuth</router-link
          >
        </li>
      </ul>

      <div v-if="Vue3GoogleOauth.isAuthorized" class="col-md-3 text-end">
        Hello {{ user }}
        <button
          type="button"
          class="btn btn-primary"
          @click="handleClickSignOut"
        >
          Logout
        </button>
      </div>
      <div v-else class="col-md-3 text-end">
        <button
          type="button"
          class="btn btn-outline-primary me-2"
          v-show="!Vue3GoogleOauth.isAuthorized"
          @click="handleClickSignIn"
        >
          Login by Google
        </button>
      </div>
    </header>
  </div>
</template>

<script>
import { inject, toRefs } from "vue";

export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },

  data() {
    return {
      user: "",
    };
  },
  setup(props) {
    const { isSignIn } = toRefs(props);
    const Vue3GoogleOauth = inject("Vue3GoogleOauth");

    const handleClickLogin = () => {};
    return {
      Vue3GoogleOauth,
      handleClickLogin,
      isSignIn,
    };
  },
  methods: {
    async handleClickSignIn() {
      try {
        const googleUser = await this.$gAuth.signIn();
        if (!googleUser) {
          return null;
        }
        console.log("googleUser", googleUser["Ba"]);
        this.user = googleUser.getBasicProfile().getEmail();
        console.log("getId", this.user);
        // console.log("getBasicProfile", googleUser.getBasicProfile());
        // console.log("getAuthResponse", googleUser.getAuthResponse());
        console.log(
          "getAuthResponse",
          this.$gAuth.instance.currentUser.get().getAuthResponse()
        );
      } catch (error) {
        //on fail do something
        console.error(error);
        return null;
      }
    },
    async handleClickSignOut() {
      try {
        await this.$gAuth.signOut();
        console.log("isAuthorized", this.Vue3GoogleOauth.isAuthorized);
        this.user = "";
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style></style>
